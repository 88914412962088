import {
    useSearchParams
} from "next/navigation"
import {
    useRouter
} from "next/router"
import {
    useDispatch,
    useSelector
} from "react-redux"
import { HandleSongEnum } from "~config/constants/enums/handle-song.enum"
import {
    orderPath
} from "~config/constants/paths"
import { useCreateVisitorMutation } from "~screens/auth/services/authApi"
import {
    setDraftLoaded,
    setSavedPath
} from "~screens/auth/store/authSlice"
import {
    resetOrderSteps,
    setArtistSelected,
    setCurrentArtist,
    setStep1FormData,
    setStep2FormData
} from "~screens/order/store/orderSlice"
import {
    setStartYourSongModal
} from "~screens/shared/store/sharedSlice"
// import { sendTaggingAddToCart } from "~services/tagging"
import { baseUrl } from '~config/base-url';

import { toast } from 'react-hot-toast';
import { useGetLastDraftMutation, useGetOrdersQuery, useOrderDetailsMuMutation } from "~screens/order/services/orderApi"
import { useEffect, useMemo } from "react"
import { getQueryParam } from "~services/cookie"
import store from '~store/index';
import { fetchWithToken } from '~config/helper/index';


export const aiArtist = {
    _id: '6666110242d0c15c1b7fc242',
    firstName: 'Instant',
    lastName: 'Song',
    stageName: 'Instant Song',
    pfpPath: 'https://res.cloudinary.com/songoven/image/upload/v1717966108/media/x7wzu9diutdrrewlsev5.webp'
}

export async function loadOrderAndRedirect(push: any, load_order_id: string) {

    const state = (store as any)?.getState?.();
    const access_token = state?.auth?.user?.access_token || ''
    const order_state = state?.order
    try {
        const updatedOrder_response = await fetchWithToken(
            access_token,
            baseUrl + `/api/order/order-details/${load_order_id}`
        );
        const updatedOrder_response_data: any = await updatedOrder_response.text();
        const updatedOrder = JSON.parse(updatedOrder_response_data);

        const newStateOrder = updateOrder(order_state, updatedOrder);
        (store as any)?.dispatch(resetOrderSteps(newStateOrder));
        (store as any)?.dispatch(setSavedPath("/"))
        push(`${orderPath}step1/for-who`)
    } catch (e) {
        if (e?.data?.message)
            toast.error(e?.data?.message)
        console.log("load order 1", e)

    }
}

function updateOrder(stateOrder: any, updatedOrder: any): any {
    return {
        ...stateOrder,
        step1FormData: {
            ...stateOrder.step1FormData,
            _id: updatedOrder._id || stateOrder.step1FormData._id,
            recipientName: updatedOrder.orderRecipient?.name || stateOrder.step1FormData.recipientName,
            isAiSong: updatedOrder.isAiSong ?? stateOrder.step1FormData.isAiSong,
            songLanguage: updatedOrder.songLanguage || stateOrder.step1FormData.songLanguage,
            mentionRecipientInSong: updatedOrder.mentionRecipientInSong ?? stateOrder.step1FormData.mentionRecipientInSong,
            recipientPronounce: updatedOrder.orderRecipient?.pronounce || stateOrder.step1FormData.recipientPronounce,
            recipientRelationship: updatedOrder.orderRecipientRelationship?.recipientRelationship?._id || stateOrder.step1FormData.recipientRelationship,
            occasion: updatedOrder.orderOccasion?.occasion?._id || stateOrder.step1FormData.occasion,
            started: true,
            existsInDB: true,
        },
        artistSelected: updatedOrder.isAiSong || (!!updatedOrder.orderArtists?.[0]) || stateOrder.artistSelected,
        currentArtist: {
            ...stateOrder.currentArtist,
            firstName: updatedOrder.orderArtists?.[0]?.user?.firstName || "",
            lastName: updatedOrder.orderArtists?.[0]?.user?.lastName || "",
            stageName: updatedOrder.orderArtists?.[0]?.user?.stageName || "",
            pfpPath: updatedOrder.orderArtists?.[0]?.user?.pfpPath || ""
        },
        step2FormData: {
            ...stateOrder.step2FormData,
            _id: updatedOrder._id || stateOrder.step1FormData._id,
            artistIds: updatedOrder.orderArtists?.map((artist: any) => artist.user._id) || stateOrder.step2FormData.artistIds,
            AIGender: updatedOrder.AIGender || stateOrder.step2FormData.AIGender,
            musicTraits: updatedOrder.orderMusicTraits?.map((trait: any) => ({
                _id: trait.musicTrait._id,
                traitType: trait.musicTrait.traitType
            })) || stateOrder.step2FormData.musicTraits,
            existsInDB: (updatedOrder.orderMusicTraits.length > 0 && !!updatedOrder.AIGender) || stateOrder.step2FormData.existsInDB,
        },
        previewSong: {
            ...stateOrder.previewSong,
            hadPreviewSong: true,
        },
        step3FormData: {
            ...stateOrder.step3FormData,
            orderId: updatedOrder._id || stateOrder.step3FormData.orderId,
            aboutSong: updatedOrder.aboutSong || stateOrder.step3FormData.aboutSong,
            orderTopicDtos: updatedOrder.orderTopics?.map((topic: any) => ({
                topicId: topic.topic._id,
                details: topic.details,
                name: topic.topic.name
            })) || stateOrder.step3FormData.orderTopicDtos,
            existsInDB: updatedOrder.orderTopics.filter((topic: any) => topic.details).length > 0 || stateOrder.step3FormData.existsInDB
        },
        step4FormData: {
            ...stateOrder.step4FormData,
            orderId: updatedOrder._id || stateOrder.step3FormData.orderId,
            deliveryDate: updatedOrder.deliveryDate || stateOrder.step4FormData.deliveryDate, // alwasy empty
            existsInDB: !!updatedOrder.deliveryDate,
            // selectedPaymentMethod: updatedOrder.selectedPaymentMethod || stateOrder.step4FormData.selectedPaymentMethod
        },
        // Continue updating other fields as necessary
    };
}


let loadingDraft = false;


function useStartSong() {

    const load_order = useMemo(() => {
        return getQueryParam('load_order') || ""
    }, [])


    const {
        push,
        replace,
        asPath,
    } = useRouter()

    const dispatch
        = useDispatch()

    const [
        createVisitor,
        { isLoading: createVisitorLoading }
    ] = useCreateVisitorMutation()


    const [
        orderDetailsMu,
        { isLoading: orderDetailsMuLoading }
    ] = useOrderDetailsMuMutation()

    const { user, draftLoaded } = useSelector((state: any) => state.auth)

    const {
        data: draftOrders, isLoading: draftOrdersLoading
    } = useGetOrdersQuery({ orderStatus: 0, limit: 1 }, { skip: (!user?.access_token) || draftLoaded || (!!load_order) })


    const order_state = useSelector((state: any) => state.order)

    const {
        step1FormData,
        step2FormData,
        step3FormData,
        step1FormData: {
            recipientName = ""
        },
        step1FormData: { _id: orderId1, },
        step2FormData: { _id: orderId2, },
        step3FormData: { orderId: orderId3, },
        step4FormData: { orderId: orderId4, }
    } = order_state || {}



    useEffect(() => {
        if (load_order) {
            if (loadingDraft) return;
            loadingDraft = true;
            (async () => {
                try {

                    const updatedOrder = await orderDetailsMu(load_order).unwrap()
                    console.log(updatedOrder)

                    // Usage
                    const newStateOrder = updateOrder(order_state, updatedOrder);
                    dispatch(resetOrderSteps(newStateOrder));

                    let redirectToURL = asPath;
                    redirectToURL = redirectToURL.replace(/load_order=.*?(&|$)/, '') // remove token if exists
                    redirectToURL = redirectToURL.replace(/(\?|&)$/, '') // if ends with questionmark or ampersand remove ending questionmark or ampersand


                    if (asPath != redirectToURL) {
                        dispatch(setSavedPath("/"))
                        replace(redirectToURL);
                    }
                    setTimeout(() => {
                        push(`${orderPath}step1/for-who`)
                    }, 500)

                } catch (e) {
                    if (e?.data?.message)
                        toast.error(e?.data?.message)
                    console.log("load order 2", e)
                }

                loadingDraft = false;
            })()
        }
    }, [load_order])

    useEffect(() => {
        if ((!draftLoaded) && draftOrders?.length>0) {
            if (loadingDraft) return;
            loadingDraft = true;

            (async () => {
                try {

                    const load_order = draftOrders[0]?._id;

                    const updatedOrder = await orderDetailsMu(load_order).unwrap()
                    console.log(updatedOrder)

                    // Usage
                    const newStateOrder = updateOrder(order_state, updatedOrder);
                    dispatch(resetOrderSteps(newStateOrder));

                } catch (e) {
                    if (e?.data?.message)
                        toast.error(e?.data?.message)
                    console.log("load order 3", e)
                }

                loadingDraft = false;
                dispatch(setDraftLoaded(true))
            })()
        }
    }, [draftLoaded, draftOrders])





    const orderId = orderId4 || orderId3 || orderId2 || orderId1 || undefined


    const continueSong = step1FormData.started

    const currentStep
        = step3FormData
            .existsInDB ?
            3
            :
            step2FormData
                .existsInDB ?
                2
                :
                step1FormData
                    .existsInDB ?
                    1
                    :
                    0


    const visitor = async (body: any) => {

        const response = await createVisitor(body || {})

        const {
            error,
        }: any = response || {}

        if (error) {
            toast.error(error
                ?.data
                ?.message
            )
        }
    }
    const savePath = () => {

        dispatch(
            setSavedPath(
                asPath
            )
        )
    }

    const startSong = async ({ recipientName = "", }: any) => {

        if (!user)
            await visitor({ orderId })

        savePath()

        if (continueSong)
            dispatch(setStartYourSongModal(true))// show dialog
        else {
            dispatch(resetOrderSteps({
                step1FormData: {
                    //  mentionOccasionInSong: false,
                    recipientName,
                    isAiSong: true,
                },
            }))

            dispatch(setStep1FormData({ isAiSong: true }))
            push(`${orderPath}step1/for-who`)
        }

        if ((window as any).gtag) {
            (window as any).gtag('event', 'start_song', {
                'event_category': 'Order',
                'event_label': 'from Hompage',
                'value': 0 // Optional: assign a value to this event
            });
        }
    }

    const startSongArtist = async (
        _id: string,
        orderId: string,
        firstName: string,
        lastName: string,
        stageName: string,
        pfpPath: string,
    ) => {

        if (!user)
            await visitor({ orderId })

        savePath()



        if (_id === aiArtist._id) {
            dispatch(resetOrderSteps({
                step1FormData: { isAiSong: true },
            }))

            dispatch(setArtistSelected(true))
            dispatch(
                setCurrentArtist({
                    firstName: aiArtist.firstName,
                    lastName: aiArtist.lastName,
                    stageName: aiArtist.stageName,
                    pfpPath: aiArtist.pfpPath
                })
            )

            dispatch(setStep2FormData({
                artistIds: [_id],
                musicTraits: []
            }))
        }
        else {
            dispatch(resetOrderSteps({
                step1FormData: { isAiSong: false },
            }))
            dispatch(setArtistSelected(true))
            dispatch(
                setCurrentArtist({
                    firstName,
                    lastName,
                    stageName,
                    pfpPath,
                })
            )

            dispatch(setStep2FormData({
                artistIds: [_id],
                musicTraits: []
            }))
        }


        push(`${orderPath}step1/for-who`)
    }

    const startSongModal = async (
        isNew: boolean,
        onReset?: any,
    ) => {

        if (!user)
            await visitor({ orderId })

        savePath()

        dispatch(setArtistSelected(false))

        if (isNew || !continueSong) {
            dispatch(resetOrderSteps({
                step1FormData: {
                    //     mentionOccasionInSong: false,
                    recipientName,
                    isAiSong: true,
                },
            }))

            dispatch(setStep1FormData({ isAiSong: true }))
            push(`${orderPath}step1/for-who`)



        } else {

            if (step3FormData?.existsInDB)
                push(`${orderPath}step4/review`)
            else if (step2FormData?.existsInDB)
                push(`${orderPath}step3/topics`)
            else if (step1FormData?.existsInDB) {
                if (step1FormData.isAiSong)
                    push(`${orderPath}step2/song-preferences`)
                else
                    push(`${orderPath}step2/pick-your-artist`)
            }
            else {
                push(`${orderPath}step1/for-who`)
            }



        }


        onReset?.()
    }

    const startSongHeader = async (type: HandleSongEnum) => {

        console.log("startSongHeader===>")

        if (type == HandleSongEnum.Continue)
            startSongModal(false);
        else if (type == HandleSongEnum.Checkout) {
            if (!user)
                await visitor({ orderId })
            push(`${orderPath}step4/delivery-time/`)
        }
        else if (type == HandleSongEnum.Start)
            startSongModal(true)

        // sendTaggingAddToCart();
        // if ((window as any).gtag) {
        //     (window as any).gtag('event', 'start_song', {
        //         'event_category': 'Order',
        //         'event_label': type == HandleSongEnum.Continue ?
        //             'Header Continue Song'
        //             :
        //             type == HandleSongEnum.Checkout ?
        //                 'Header Continue Addons'
        //                 :
        //                 type == HandleSongEnum.Start ?
        //                     'Header Start Song'
        //                     :
        //                     '',
        //         'value': 0
        //     });
        // }

    }

    const isLoading =
        createVisitorLoading ||
        orderDetailsMuLoading

    return {
        isLoading,
        currentStep,
        continueSong,
        startSong,
        startSongArtist,
        startSongModal,
        startSongHeader,
    }
}

export default useStartSong